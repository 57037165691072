import { Component } from "react";
import { connect } from 'react-redux'
import Loading from './Loading'
import Http from '../services'
class LoginForm extends Component {

  state = {
    form: {
      email: '',
      password: ''
    },
    isLoading: true,
  }

  componentDidMount () {
    this.getApiKey()
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ isLoading: true, error: null })

    try {
      const headers = new Headers()
      headers.append("X-Requested-With", "XMLHttpRequest")
      headers.append("Content-Type", "application/json")
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          "grant_type": "password",
          username: this.state.form.email,
          password: this.state.form.password,
          "client_id": this.props.user.id,
          "client_secret": this.props.user.secret
        })
      }
      const tokenResponse  = await fetch(`https://massaggi.caribito.com/oauth/token`, options)
      const token = await tokenResponse.json()
      this.props.dispatch({
        type: 'SET_TOKEN',
        payload: token
      })
      this.setState({ isLoading: false })
      this.props.history.push('/');
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false, error: error })
    }
  }

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    })
  }

  getApiKey = async () => {
    try {
      const apiKey = await Http.get('https://massaggi.caribito.com/oauth/client')
      this.props.dispatch({ type: 'SET_API_KEY', payload: apiKey })
      this.setState({ isLoading: false })
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false })
    }
  }

  render() {
    return (
      <>
        <Loading isOpen={this.state.isLoading}></Loading>
        <form
          className="col-10 col-md-4 bg-white shadow p-3 my-5"
          onSubmit={() => this.handleSubmit()}
        >
          <div className="mb-3">
            <label
              htmlFor="email"
              className="form-label font-weight-bold"
            >
              Email
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="someone@example.com"
              onChange={this.handleChange}
              value={this.state.form.email}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="password"
              className="form-label font-weight-bold"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              className="form-control"
              onChange={this.handleChange}
              value={this.state.form.password}
            />
          </div>
          <div className="text-center">
            <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>
              Login
            </button>
          </div>
        </form>
      </>
    )
  }
}


const mapStateToProp = ({user}, props) => ({ user })

export default connect(mapStateToProp)(LoginForm)
